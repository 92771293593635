import React from "react"
import SectionHeading from "../../components/SectionHeading"
import ContentContainer from "../../templates/ContentContainer"

const TermsContentItem: React.FC = ({ children }) => {
    return (
        <p className="text-lg text-horizen-content-grey pt-4 pb-8">
            {children}
        </p>
    )
}

const TermsContent = () => {
    return (
        <section className="-mt-14">
            <ContentContainer>
                <div>
                    <TermsContentItem>
                        These Terms of Use (the “Terms”) apply to your access to
                        and use of the websites, platform, software,
                        technologies, features, and other online products and
                        services (collectively, the “Services”) provided or made
                        available by Horizen Foundation (“Foundation” “us” “we”
                        or “our”).
                    </TermsContentItem>
                    <TermsContentItem>
                        For purposes of these Terms, “user”, “you”, and “your”
                        means you as the user of the Services. If you use the
                        Services on behalf of a company or other entity, then
                        “you” includes you and that entity, and you represent
                        and warrant that (a) you are an authorized
                        representative of the entity with the authority to bind
                        the entity to these Terms, and (b) you agree to these
                        Terms on the entity’s behalf.
                    </TermsContentItem>
                    <TermsContentItem>
                        Currently, the Foundation maintains and operates{" "}
                        <a
                            href="https://www.horizen.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-old-design-blue"
                        >
                            www.horizen.io
                        </a>{" "}
                        (the “Site”) as a portal for news, information, and
                        updates about the Horizen protocol or blockchain
                        (“Horizen”), the Horizen sidechains, including but not
                        limited to the EON sidechain (collectively, the
                        “Sidechains” together with Horizen referred to as the
                        “Network”), and the Horizen ecosystem. For the avoidance
                        of doubt, the Foundation does not control the Network
                        and cannot control activity and data on the Network, the
                        activities of persons who develop and use applications
                        on the Network, the validation of transactions on the
                        Network, or use of the Network. The Network is an
                        open-source protocol that is maintained and processed by
                        Network validators across the globe.
                    </TermsContentItem>
                    <TermsContentItem>
                        The Foundation does not guarantee that access to or use
                        of the Site or any of the Services will be uninterrupted
                        or error-free, and the Foundation shall not be liable
                        for any feature not being accessible or for any
                        unavailability of the Services. The Services may be
                        expanded, limited, or modified at any time by the
                        Foundation without advance notice or reason. The
                        Foundation may also, in its sole and absolute sole
                        discretion, and at any time, discontinue providing,
                        temporarily or permanently, any or all of the Services,
                        without notice. To access some of the content or
                        features of the Services, Users may need to enhance or
                        update the hardware or software in their computer
                        systems. The Foundation assumes no responsibility for
                        any failure to access the Services, partially or fully,
                        whether due to the user&apos;s system, Internet network,
                        or any other cause.
                    </TermsContentItem>
                    <TermsContentItem>
                        The Terms represent a binding contract between you and
                        us, concerning the use of the Services, including the
                        Site as well as any other media form related, linked, or
                        otherwise connected to the Site or any other Services.
                    </TermsContentItem>
                    <TermsContentItem>
                        PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN
                        IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. BY
                        CLICKING TO ACCEPT AND/OR USING OUR SERVICES, YOU AGREE
                        TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS
                        INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO
                        THESE TERMS, YOU MAY NOT ACCESS OR USE THE SERVICES.
                    </TermsContentItem>
                    <TermsContentItem>
                        Some jurisdictions may impose limitations on the use of
                        Services and Users in those jurisdictions should consult
                        with independent legal counsel before using the
                        Services.
                    </TermsContentItem>
                    <SectionHeading>Privacy Policy</SectionHeading>
                    <TermsContentItem>
                        Please refer to our Privacy Policy for more details on
                        the information that the Foundation may collect, use and
                        disclose and under what circumstances. You acknowledge
                        and agree that your use of the Services is subject to
                        this Privacy Policy.
                    </TermsContentItem>
                    <SectionHeading>Changes to Terms</SectionHeading>
                    <TermsContentItem>
                        The Foundation may update the Terms at any time, at its
                        sole discretion. If it does so, the Foundation will
                        deliver a notice either by posting the updated Terms on
                        its website, on any applicable blog or forum used for
                        sharing information or through other communications.
                        It’s important that you review any and all updated
                        Terms. If you continue to use the Services after the
                        Foundation has posted updated Terms, you agree to be
                        bound by the updated Terms.
                    </TermsContentItem>
                    <SectionHeading>Intellectual Property</SectionHeading>
                    <TermsContentItem>
                        The Service, including its “look and feel” (e.g., text,
                        graphics, images, logos, page headers, button icons, and
                        scripts), proprietary content, information and other
                        materials, and all content and other materials contained
                        therein, including, without limitation, the Network
                        brands, logos and all designs, text, graphics, pictures,
                        data, software, sound files, other files, and the
                        selection and arrangement thereof are the proprietary
                        property of us or our affiliates, licensors, or users,
                        as applicable, and you agree not to take any action(s)
                        inconsistent with such ownership interests. We and our
                        affiliates, licensors, and users, as applicable, reserve
                        all rights in connection with the Service and its
                        content, including, without limitation, the exclusive
                        right to create derivative works.
                    </TermsContentItem>
                    <TermsContentItem>
                        The Foundation’s name, logo, trademarks, and any of the
                        Network product or service names, designs, logos, and
                        slogans are the intellectual property of the Foundation
                        or our affiliates or licensors and may not be copied,
                        imitated, or used, in whole or in part, without our
                        prior written permission in each instance. You may not
                        use any metatags or other “hidden text” utilizing
                        “Horizen” or any other name, trademark or product or
                        service name of the Foundation, Horizen or our
                        affiliates or licensors without our prior written
                        permission. In addition, the “look and feel” of the
                        Services constitutes the service mark, trademark, or
                        trade dress of the Foundation and may not be copied,
                        imitated or used, in whole or in part, without our prior
                        written permission.
                    </TermsContentItem>
                    <TermsContentItem>
                        All other third-party trademarks, registered trademarks,
                        and product names mentioned on the Services or contained
                        in the content linked to or associated with any tokens
                        displayed on the Services are the property of their
                        respective owners and may not be copied, imitated, or
                        used, in whole or in part, without the permission of the
                        applicable intellectual property rights holder.
                        Reference to any products, services, processes or other
                        information by name, trademark, manufacturer, supplier
                        or otherwise does not constitute or imply endorsement,
                        sponsorship, or recommendation by the Foundation.
                    </TermsContentItem>
                    <SectionHeading>Usage Data Analytics</SectionHeading>
                    <TermsContentItem>
                        The Foundation may collect usage information that allows
                        us to collect information regarding how users access and
                        use the Services (“Usage Data”). We use this information
                        to analyze trends, administer the Services, troubleshoot
                        any user problems, and to enhance and update the
                        Services. We may do this Usage Data collection by using
                        third parties.
                    </TermsContentItem>
                    <SectionHeading>Security</SectionHeading>
                    <TermsContentItem>
                        You are responsible for maintaining adequate security
                        and control of any and all IDs, passwords, hints,
                        personal identification numbers (PINs), or any other
                        codes that you use to access the Network as part of your
                        systems. The Foundation assumes no responsibility for
                        any losses resulting from any compromise of your systems
                        or wallet(s).
                    </TermsContentItem>
                    <SectionHeading>Prohibited Activities</SectionHeading>
                    <TermsContentItem>
                        You are granted permission to access, download, and use
                        the Services, provided that you shall:
                        <ul className="list-disc list-outside py-8 pl-8">
                            <li>
                                Not use the Services for any illegal or
                                unauthorized purpose or beyond the scope of the
                                Services expected use;
                            </li>
                            <li>
                                Not intentionally interfere with the operation
                                of the Services or with any other person’s use
                                of the Services;
                            </li>
                            <li>
                                Not intentionally gain unauthorized access to
                                the Services;
                            </li>
                            <li>
                                Be solely liable for your, including without
                                limitation all of your employees, affiliates,
                                consultants, and service providers, conduct,
                                acts and omissions;
                            </li>
                            <li>Not hack the Services;</li>
                            <li>
                                Not remove or alter any copyright notices or
                                other notices included in the Services;
                            </li>
                            <li>
                                Not decipher, decompile, disassemble, or reverse
                                engineer any of the software comprising or in
                                any way making up a part of the Services in an
                                unauthorized manner;
                            </li>
                            <li>
                                Not intentionally transmit any viruses, bugs,
                                worms, or any other computer code of a
                                destructive nature or that may harm a network,
                                computer, server, hardware, software or
                                telephone equipment using the Services;
                            </li>
                            <li>
                                Not violate anyone else’s legal rights (e.g.
                                privacy rights) or any laws (e.g. copyright
                                laws) in your jurisdiction while using the
                                Services;
                            </li>
                            <li>
                                Use the Services in accordance with all
                                applicable laws;
                            </li>
                            <li>
                                Not use any unauthorized data mining, robots,
                                spyware or similar data gathering and extraction
                                methods in connection with the Services;
                            </li>
                            <li>
                                Not intentionally impose an unreasonable burden
                                on the Services or network;
                            </li>
                            <li>
                                Not breach, or attempt to breach, or otherwise
                                interfere with the security of the Services;
                            </li>
                            <li>
                                Not use the Services or export the Services in
                                violation of U.S. export laws and regulations;
                            </li>
                            <li>
                                Not solicit any activity, unlawful or otherwise,
                                that infringes the Foundation’s rights or the
                                rights of any other party;
                            </li>
                            <li>
                                Not enable others to violate any of these terms
                                and conditions; and
                            </li>
                            <li>Be at least 18 years of age or older.</li>
                        </ul>
                        A violation of any of these Terms, as determined in
                        Foundation’s sole discretion, may result in the
                        termination of the User’s ability to access or use the
                        Services.
                    </TermsContentItem>
                    <SectionHeading>Compliance with Laws</SectionHeading>
                    <TermsContentItem>
                        You agree to comply with all applicable laws, rules,
                        regulations, and any generally accepted practices or
                        guidelines in the relevant jurisdictions (including any
                        laws regarding the export of data or software to and
                        from the United States or other relevant countries and
                        all applicable privacy and data collection laws and
                        regulations) in connection with your use of the Site and
                        Services. To the extent required by law, you are solely
                        responsible for obtaining or filing any approval,
                        clearance, registration, permit, or other regulatory
                        authorization and shall comply with the requirements of
                        such authorization.
                    </TermsContentItem>
                    <SectionHeading>Third-Party Content</SectionHeading>
                    <TermsContentItem>
                        Any testimonials, opinions, advice, product or service
                        offers, or other information or content made available
                        on or through the Services by third parties
                        (&quot;Third-Party Content&quot;) are solely those of
                        their respective providers and not of the Foundation
                        which does not guarantee the accuracy, completeness,
                        reliability or usefulness of Third-Party Content. It is
                        the responsibility of each user to evaluate Third-Party
                        Content and the Foundation shall not be liable for any
                        damage or loss caused by any users&apos; reliance on or
                        other use of Third Party Content.
                    </TermsContentItem>
                    <SectionHeading>Access to Third Parties</SectionHeading>
                    <TermsContentItem>
                        If you provide access to the Services to third parties,
                        you will be solely responsible to ensure that each user
                        has previously agreed to minimum end user terms and
                        conditions governing their use and access, where such
                        terms and conditions shall:
                        <ul>
                            <li>
                                (a) be prominent, written, accurate and
                                easy-to-understand by a reasonable consumer,
                            </li>
                            <li>
                                (b) provide clear and conspicuous disclosures to
                                all users and prospective users sufficient to
                                comply with applicable law,
                            </li>
                            <li>
                                (c) be at least as restrictive as these Terms,{" "}
                            </li>
                            <li>
                                (d) provide users liability restrictions and
                                limitations and warranty disclaimers, to include
                                but not be limited to:
                                <ul className="ml-4">
                                    <li>
                                        (i) exclusion of all implied warranties,
                                        including without limitation for
                                        merchantability and fitness for a
                                        particular purpose,
                                    </li>
                                    <li>
                                        (ii) exclusion of consequential,
                                        special, indirect, incidental, punitive,
                                        exemplary and tort damages, and
                                    </li>
                                    <li>
                                        (iii) inclusion of a quantifiable
                                        limitation of liability for direct and
                                        indirect damages that is less than the
                                        liability limits set forth in this
                                        Agreement;
                                    </li>
                                </ul>
                            </li>
                            <li>
                                (e) provide clear instructions for users on how
                                to retrieve their private wallet keys;
                            </li>
                            <li>
                                (f) release the Foundation and its suppliers of
                                all liability and obligation related to any
                                delays, inaccuracies or incomplete services
                                caused by the failure of the Foundation or its
                                suppliers.
                            </li>
                        </ul>
                    </TermsContentItem>
                    <SectionHeading>Offsite Links</SectionHeading>
                    <TermsContentItem>
                        As a convenience to each user, the Foundation may
                        provide links to other sites or resources. Because the
                        Foundation does not have control over such sites and
                        resources, the Foundation is not responsible or liable
                        for use of or reliance on any content, products,
                        services or information at such sites or resources.
                        Inclusion of any links does not imply any endorsement,
                        affiliation, approval, association or sponsorship by the
                        Foundation of the linked websites, resources, their
                        operators or owners. When you select a link, you may be
                        leaving our Services. The information available on the
                        third parties&apos; websites may have certain
                        restrictions on use or distribution which differ from
                        these Terms.
                    </TermsContentItem>
                    <SectionHeading>WARRANTY DISCLAIMERS</SectionHeading>
                    <TermsContentItem>
                        THE FOUNDATION MAKES NO, AND HEREBY DISCLAIMS ALL
                        REPRESENTATIONS AND WARRANTIES OF ANY KIND WITH RESPECT
                        TO THE SERVICES. THE SERVICES ARE PROVIDED “AS IS” AND
                        WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND.
                        WITHOUT LIMITING THE FOREGOING, THE FOUNDATION
                        EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        VALUE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                        OUT OF COURSE OF DEALING OR USAGE OF TRADE.
                    </TermsContentItem>
                    <SectionHeading>LIMITATION OF LIABILITY</SectionHeading>
                    <TermsContentItem>
                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL
                        WE OR OUR AFFILIATES, SUPPLIERS OR LICENSORS BE LIABLE
                        TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
                        SPECIAL, OR CONSEQUENTIAL DAMAGES OR FOR LOSS OF USE,
                        LOST PROFITS, OR LOSS OF DATA ARISING OUT OF OR RELATED
                        TO THESE TERMS OR THE SITE AND SERVICES, HOWEVER CAUSED
                        AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN
                        CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF
                        WE OR OUR SUPPLIERS OR LICENSORS HAVE BEEN ADVISED OF
                        THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT YOUR USE
                        OF THE SITE AND SERVICES WILL BE AT YOUR SOLE RISK.
                    </TermsContentItem>
                    <TermsContentItem>
                        THE FOUNDATION IS NOT RESPONSIBLE FOR ANY DAMAGES OR
                        LOSSES THAT RESULT FROM YOUR USE OF THE SERVICE,
                        INCLUDING, BUT NOT LIMITED TO, YOUR USE OR INABILITY TO
                        USE THE SERVICE; ANY CHANGES TO OR INACCESSIBILITY OR
                        TERMINATION OF THE SERVICE; ANY DELAY, FAILURE,
                        UNAUTHORIZED ACCESS TO, OR ALTERATION OF ANY
                        TRANSMISSION OR DATA; ANY TRANSACTION OR AGREEMENT
                        ENTERED INTO THROUGH THE SERVICE; ANY ACTIVITIES OR
                        COMMUNICATIONS OF THIRD PARTIES; OR ANY DATA OR MATERIAL
                        FROM A THIRD PERSON ACCESSED ON OR THROUGH THE SERVICES.
                        WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                        ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
                        CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
                        ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
                        ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                        MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
                        ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
                        USE OF THE SITE OR SERVICE, (3) ANY UNAUTHORIZED ACCESS
                        TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
                        THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                        TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
                        TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
                        OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY
                        ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
                        THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
                        MADE AVAILABLE VIA THE SITE.
                    </TermsContentItem>
                    <TermsContentItem>
                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL
                        THE CUMULATIVE LIABILITY OF US, OUR SUPPLIERS, AND OUR
                        LICENSORS FOR ANY CLAIMS ARISING OUT OF OR RELATED TO
                        THESE TERMS OR THE SITE OR SERVICES EXCEED ONE HUNDRED
                        DOLLARS ($100).
                    </TermsContentItem>
                    <TermsContentItem>
                        THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH
                        ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE
                        BARGAIN BETWEEN FOUNDATION AND YOU.
                    </TermsContentItem>
                    <TermsContentItem>
                        You shall and hereby do waive California Civil Code
                        Section 1542 or any other similar law of any
                        jurisdiction, which says in substance: “A general
                        release does not extend to claims which the creditor
                        does not know or suspect to exist in his favor at the
                        time of executing the release, which, if known by him
                        must have materially affected his settlement with the
                        debtor.” Some jurisdictions do not allow the exclusion
                        of implied warranties, so the above exclusion may not
                        apply to you. You may have other rights which vary from
                        jurisdiction to jurisdiction.
                    </TermsContentItem>
                    <SectionHeading>Indemnification</SectionHeading>
                    <TermsContentItem>
                        To the fullest extent permitted by applicable law, you
                        will indemnify, defend and hold the Foundation and/or
                        subsidiaries and affiliates harmless, and each of our
                        respective officers, directors, agents, partners and
                        employees (individually and collectively, the
                        “Foundation Parties”) from and against any losses,
                        liabilities, claims, demands, damages, expenses or costs
                        (“Claims”) arising out of or related to (a) your access
                        to or use of the Services; (b) your violation of these
                        Terms; (c) your violation, misappropriation or
                        infringement of any rights of another (including
                        intellectual property rights or privacy rights); or (d)
                        your conduct in connection with the Services. You agree
                        to promptly notify the Foundation Parties of any
                        third-party Claims, cooperate with the Foundation
                        Parties in defending such Claims and pay all fees, costs
                        and expenses associated with defending such Claims
                        (including attorneys’ fees). You also agree that the
                        Foundation Parties will have control of the defense or
                        settlement, at Foundation’s sole option, of any
                        third-party Claims.
                    </TermsContentItem>
                    <SectionHeading>
                        Governing law and other legal matters
                    </SectionHeading>
                    <TermsContentItem>
                        These Terms are governed by the laws of the State of New
                        York without reference to the principles of conflicts of
                        laws thereof. Any legal suit, action, or proceeding
                        arising out of, or related to, these Terms shall be
                        instituted exclusively in the courts of New York
                        although we retain the right to bring any suit, action,
                        or proceeding against you for breach of these Terms in
                        your country of residence or any other relevant country.
                        You waive any and all objections to the exercise of
                        jurisdiction over you by such courts and to venue in
                        such courts.
                    </TermsContentItem>
                    <TermsContentItem>
                        At Foundation’s sole discretion, it may require you to
                        submit any disputes arising from the use of these Terms,
                        including disputes arising from or concerning their
                        interpretation, violation, invalidity, non-performance,
                        or termination, to final and binding arbitration under
                        the American Arbitration Association.
                    </TermsContentItem>
                    <TermsContentItem>
                        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF
                        OR RELATING TO THESE TERMS MUST BE COMMENCED WITHIN ONE
                        (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE,
                        SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
                    </TermsContentItem>
                    <TermsContentItem>
                        No waiver by the Foundation of any term or condition set
                        out in these Terms shall be deemed a further or
                        continuing waiver of such term or condition or a waiver
                        of any other term or condition, and any failure of the
                        Foundation to assert a right or provision under these
                        Terms shall not constitute a waiver of such right or
                        provision. If any provision of these Terms is held by a
                        court or other tribunal of competent jurisdiction to be
                        invalid, illegal or unenforceable for any reason, such
                        provision shall be eliminated or limited to the minimum
                        extent such that the remaining provisions of the Terms
                        will continue in full force and effect.
                    </TermsContentItem>
                    <TermsContentItem>
                        Any ambiguities herein will not be strictly construed
                        against the drafter of the language concerned but will
                        be resolved by applying the most reasonable
                        interpretation under the circumstances, giving full
                        consideration to the intentions of the parties at the
                        time of contracting. These Terms will not be construed
                        against any party by reason of its preparation. In the
                        event any suit or other action is commenced by the
                        Foundation to construe or enforce any provision of these
                        Terms, in addition to all other amounts, the Foundation
                        shall be entitled to receive from you, the Foundation
                        shall recover its reasonable attorneys&apos; fees and
                        court costs, to the extent permitted by applicable law.
                    </TermsContentItem>
                    <SectionHeading>Assignment</SectionHeading>
                    <TermsContentItem>
                        You may not sell, assign or transfer any of your rights,
                        duties or obligations under these Terms without our
                        prior written consent. Any attempt by you to assign or
                        transfer the Terms, without such consent, will be null
                        and void. We reserve the right to assign or transfer
                        these Terms or any of its rights, duties and obligations
                        hereunder to any third party. Subject to the foregoing,
                        these Terms will bind and insure to the benefit of the
                        parties, their successors and permitted assigns.
                    </TermsContentItem>
                    <SectionHeading>General Terms</SectionHeading>
                    <TermsContentItem>
                        Nothing herein shall constitute an employment,
                        consultancy, joint venture, or partnership relationship
                        between you and the Foundation. If any provision of the
                        Terms is held invalid or unenforceable by an arbitrator
                        or a court of competent jurisdiction, that provision
                        will be enforced to the maximum extent permissible and
                        the other provisions of the Terms will remain in full
                        force and effect. These Terms reflect the entire
                        agreement between the parties relating to the subject
                        matter hereof and supersede all prior agreements,
                        representations, statements and understandings of the
                        parties. The section titles in these Terms are for
                        convenience only and have no legal or contractual
                        effect. Use of the word “including” will be interpreted
                        to mean “including without limitation.”
                    </TermsContentItem>
                    <SectionHeading>Waiver of Rights</SectionHeading>
                    <TermsContentItem>
                        The Foundation’s failure to enforce any right or
                        provision of these Terms will not be considered a waiver
                        of such right or provision. The waiver of any such right
                        or provision will be effective only if in writing and
                        signed by a duly authorized representative of the
                        Foundation. Except as expressly set forth in these Terms
                        , the exercise by either party of any of its remedies
                        under these Terms will be without prejudice to its other
                        remedies under these Terms or otherwise.
                    </TermsContentItem>
                    <TermsContentItem>
                        All other feedback, comments, requests for technical
                        support, and other communications relating to the
                        Services should be directed to: legal@horizen.io.
                    </TermsContentItem>
                    <TermsContentItem>
                        These terms were last updated on August 22, 2023.
                    </TermsContentItem>
                    <div className="text-center text-lg text-horizen-content-grey pt-8">
                        [ End of Terms ]
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default TermsContent
