import React from "react"
import { Helmet } from "react-helmet"

const TermsHelmet = () => {
    return (
        <Helmet>
            <title>Horizen - Terms of Use</title>
            <meta property="og:title" content="Horizen - Terms of Use" />
            <meta name="twitter:title" content="Horizen - Terms of Use" />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Horizen's terms of use outline regulations that users must agree to follow in order to use our services."
            />
            <meta
                property="og:description"
                content="Horizen's terms of use outline regulations that users must agree to follow in order to use our services."
            />
            <meta
                name="twitter:description"
                content="Horizen's terms of use outline regulations that users must agree to follow in order to use our services."
            />
            <meta
                name="keywords"
                content="horizen terms of use, terms of use, zencash terms of use"
            />
            <link rel="canonical" href="https://www.horizen.io/terms/" />
            <meta property="og:url" content="https://www.horizen.io/terms/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default TermsHelmet
