import React from "react"
import NewsletterForm from "../components/NewsletterForm"
import TermsHelmet from "../components/SEO/TermsHelmet"
import TermsContent from "../sections/terms/TermsContent"
import TermsHero from "../sections/terms/TermsHero"
import Layout from "../templates/Layout"

import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"

const Terms = () => {
    return (
        <Layout>
            <TermsHelmet />
            <div className="bg-horizen-hp-bg text-white">
                <TermsHero />
                <TermsContent />
                <div
                    className="pt-80 -mt-48"
                    style={{
                        backgroundImage: `url(${LatestUpdatesBackground})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "50% 65%",
                    }}
                >
                    <NewsletterForm />
                </div>
            </div>
        </Layout>
    )
}

export default Terms
