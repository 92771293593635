import React from "react"
import NormalPageHero from "../../components/NormalPageHero"

import * as styles from "../../styles/terms/terms.module.css"

const TermsHero = () => {
    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.termsHero}`}
        >
            <NormalPageHero titleKey="terms.hero.sectionHeading" />
        </section>
    )
}

export default TermsHero
